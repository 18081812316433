// @ts-strict-ignore
import React, { useState } from "react"

import { format } from "date-fns-tz"

import {
  Box,
  Button,
  InputField,
  Popover,
  useMediaQuery,
} from "@kiwicom/orbit-components"
import { Calendar } from "@kiwicom/orbit-components/icons"

import { EmberInlineDatePicker } from "../date-time/ember-inline-date-picker"

interface EmberDatePickerProps extends React.ComponentProps<typeof InputField> {
  date: Date
  setDate: (value: Date) => void
  minDate?: Date
  maxDate?: Date
  fullWidth?: boolean
  showDay?: boolean
  showTwoMonths?: boolean
  showTimeInput?: boolean
  shrinkOnMobile?: boolean
  iconOnly?: boolean
  icon?: React.ReactNode
  dataTest?: string
  timezone?: string
}

const EmberDatePicker = ({
  date,
  setDate,
  minDate,
  maxDate,
  fullWidth = false,
  showDay = true,
  showTwoMonths = true,
  showTimeInput = false,
  shrinkOnMobile = false,
  iconOnly = false,
  icon = null,
  dataTest = null,
  timezone = null,
  ...props
}: EmberDatePickerProps) => {
  const { isLargeMobile } = useMediaQuery()

  const dateFormat =
    shrinkOnMobile && !isLargeMobile
      ? "d MMM yy"
      : showDay
      ? "eee d MMM yyyy"
      : "d MMM yyyy"
  const [isOpen, setIsOpen] = useState(false)

  function displayValue() {
    if (date) {
      return format(date, dateFormat)
    } else {
      return null
    }
  }

  return (
    // Outer div important to make component work as last item in horizontal stack
    // Without it, you end up with padding to the right when the popover is opened
    // due to an extra final element appearing in the dom
    <div>
      <Popover
        allowOverflow={false}
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        renderInPortal={false}
        noPadding
        lockScrolling={false}
        content={
          <EmberInlineDatePicker
            date={date}
            setDate={setDate}
            minDate={minDate}
            maxDate={maxDate}
            numberMonths={showTwoMonths ? 2 : 1}
            showTimeInput={showTimeInput}
            closeOnSelect={() => setIsOpen(false)}
            timezone={timezone}
          />
        }
      >
        <div onClick={() => setIsOpen(true)} data-test={dataTest}>
          {iconOnly &&
            (icon || (
              <Button type="secondary" size="small" iconLeft={<Calendar />} />
            ))}
          {!iconOnly && (
            <Box
              width={
                fullWidth
                  ? ""
                  : shrinkOnMobile && !isLargeMobile
                  ? "110px"
                  : showDay
                  ? "170px"
                  : "140px"
              }
            >
              <InputField {...props} value={displayValue()} readOnly={true} />
            </Box>
          )}
        </div>
      </Popover>
    </div>
  )
}

export { EmberDatePicker }
