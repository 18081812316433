// @ts-strict-ignore
import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { Alert, Button, Loading, Stack, Text } from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { modifyIssue } from "api/issues"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import {
  EmberFormSelect,
  EmberTextArea,
} from "components/generic/formik-inputs"

import { Issue, IssueSeverity } from "types/issue"

import { useGlobalFetcher } from "utils/state-utils"

import { compileCategoryOptions } from "../shared/loaders"

/**
 * This is the modal that pops up when the user selects a status that closes the issue. We prompt
 * them for a resolution note and category before closing.
 */
export const IssueResolutionModal = ({
  issue,
  setIssue,
  pendingChanges,
  onClose,
}: {
  issue: Issue
  setIssue: (newIssue: Issue) => void
  pendingChanges: Partial<Issue>
  onClose: () => void
}) => {
  return (
    <EmberModal onClose={onClose} dataTest="issue-resolution-modal">
      <EmberModalHeader
        title="Resolve This Issue"
        description="Please ensure the fields below are completed and accurate"
      />
      <EmberModalSection>
        <IssueResolutionModalBody
          issue={issue}
          setIssue={setIssue}
          pendingChanges={pendingChanges}
          onClose={onClose}
        />
      </EmberModalSection>
    </EmberModal>
  )
}

const IssueResolutionModalBody = ({
  issue,
  setIssue,
  pendingChanges,
  onClose,
}: {
  issue: Issue
  setIssue: (newIssue: Issue) => void
  pendingChanges: Partial<Issue>
  onClose: () => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<EmberApiError>()

  const save = ({ category, resolutionNote }) => {
    setLoading(true)
    setApiError(null)
    modifyIssue({
      issue,
      changes: {
        categoryUid: category.id,
        resolutionNote,
        ...pendingChanges,
      },
      onSuccess: (issue: Issue) => {
        setLoading(false)
        setIssue(issue)
        onClose()
      },
      onError: (error: EmberApiError) => {
        setLoading(false)
        setApiError(error)
      },
    })
  }

  const issueCategoriesFetcher = useGlobalFetcher("issueCategories")

  if (issueCategoriesFetcher.errorMessage) {
    return (
      <Alert type="critical">
        <Text>
          Failed to fetch issue categories:{" "}
          {issueCategoriesFetcher.errorMessage}
        </Text>
      </Alert>
    )
  }

  if (!issueCategoriesFetcher.data) {
    return <Loading />
  }

  const options = compileCategoryOptions({
    issueCategories: issueCategoriesFetcher.data,
    currentCategory: issue.category,
    includeNotCategorisedOption: false, // you have to pick a category when closing the issue
  })

  return (
    <Formik
      initialValues={{
        category: issue.category
          ? options.find((o) => o.id == issue.category.uid)
          : { id: null, title: "" },
        resolutionNote: issue.resolutionNote || "",
      }}
      validationSchema={Yup.object({
        category: Yup.object().test(
          "category-is-set",
          "Please select the appropriate category",
          (category) => !!category?.id
        ),
        resolutionNote: Yup.string().required(
          "Please describe how the issue was resolved"
        ),
      })}
      onSubmit={save}
    >
      {({ values }) => (
        <Form>
          <Stack spacing="large">
            <EmberFormSelect
              name="category"
              label="Category"
              options={options}
              dataTest="issue-resolution-modal-category"
            />
            <EmberTextArea
              name="resolutionNote"
              label="Resolution Note"
              dataTest="issue-resolution-modal-resolution-note"
            />

            {issue.severity == IssueSeverity.ASSET_UNUSABLE && (
              <Text>
                <Text as="span" weight="bold">
                  Warning:{" "}
                </Text>
                When all critical issues on this vehicle are resolved the system
                will consider it eligible for service use.
              </Text>
            )}

            <Stack justify="end" direction="row">
              <Stack shrink justify="end" direction="row">
                <Button type="secondary" disabled={loading} onClick={onClose}>
                  Cancel and leave the issue open
                </Button>
              </Stack>
              <Button
                submit
                loading={loading}
                disabled={!values.resolutionNote || !values.category}
                dataTest="issue-resolution-modal-submit"
              >
                Resolve
              </Button>
            </Stack>

            {apiError && (
              <Alert type="critical">
                <Text>{apiError.message}</Text>
              </Alert>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
