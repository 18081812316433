// @ts-strict-ignore
import React, { useContext, useEffect, useState } from "react"

import styled, { ThemeContext } from "styled-components"

import {
  Alert,
  Box,
  Checkbox,
  InputField,
  Loading,
  Stack,
  Text,
  Truncate,
} from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { IssueQuery, fetchIssues } from "api/issues"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { EmberListChoice } from "components/generic/ember-select"

import {
  ACTIVE_ISSUE_STATUSES,
  INACTIVE_ISSUE_STATUSES,
  IssueSummary,
  issueStatusToText,
} from "types/issue"

export const MAX_ISSUES_IN_MODAL = 50

export const IssueSelectionModal = ({
  onClose,
  onIssueSelected,
  excludedIssueUids = [],
  filterVehicleId = undefined,
}: {
  onClose: () => void
  onIssueSelected: (selectedIssue: IssueSummary) => void
  excludedIssueUids?: string[]
  filterVehicleId?: number
}) => (
  <EmberModal onClose={onClose} size="small">
    <EmberModalHeader title="Select the duplicated issue" />
    <EmberModalSection>
      <IssueSelectionModalBody
        onIssueSelected={onIssueSelected}
        excludedIssueUids={excludedIssueUids}
        filterVehicleId={filterVehicleId}
      />
    </EmberModalSection>
  </EmberModal>
)

const IssueSelectionModalBody = ({
  onIssueSelected,
  excludedIssueUids,
  filterVehicleId,
}: {
  onIssueSelected: (duplicate: IssueSummary) => void
  excludedIssueUids: string[]
  filterVehicleId?: number
}) => {
  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [issues, setIssues] = useState<IssueSummary[]>()
  const [total, setTotal] = useState<number>(0)
  const [error, setError] = useState<EmberApiError>()
  const [textSearch, setTextSearch] = useState<string>("")
  const [includeClosed, setIncludeClosed] = useState<boolean>(false)

  const [query, setQuery] = useState<IssueQuery>()
  const [submittedQuery, setSubmittedQuery] = useState<IssueQuery>()

  useEffect(() => {
    setQuery({
      sortColumnId: "createdAt",
      sortReversed: true,
      textQuery: textSearch?.trim() || null,
      status: includeClosed ? null : ACTIVE_ISSUE_STATUSES,
      limit: MAX_ISSUES_IN_MODAL,
      vehicleId: filterVehicleId ? [filterVehicleId] : null,
    })
  }, [textSearch, includeClosed])

  // If the user keeps typing while we're fetching, don't fire a new request just now, we'll fire
  // off a new one when we're done with the one that's in flight now
  useEffect(() => {
    if (!loading && query != submittedQuery) {
      setSubmittedQuery(query)
    }
  }, [loading, query, submittedQuery])

  useEffect(() => {
    if (!submittedQuery) {
      return
    }
    setLoading(true)
    fetchIssues({
      query: submittedQuery,
      onSuccess: (issues: IssueSummary[], total: number) => {
        setIssues(issues)
        setTotal(total)
        setLoading(false)
      },
      onError: (error: EmberApiError) => {
        setError(error)
        setLoading(false)
      },
    })
  }, [submittedQuery])

  return (
    <Stack direction="column" spacing="XLarge" grow>
      <Stack direction="column">
        <InputField
          value={textSearch}
          onChange={(event) => setTextSearch(event.currentTarget.value)}
          placeholder="Search"
          suffix={
            loading && (
              <Box padding={{ top: "XSmall", right: "small" }}>
                <Loading
                  type="inlineLoader"
                  customSize={parseInt(theme.orbit.spaceLarge)}
                />
              </Box>
            )
          }
          dataTest="issue-selection-text-search"
        />
        <Checkbox
          label="Include closed issues"
          checked={includeClosed}
          onChange={(event) => setIncludeClosed(event.currentTarget.checked)}
          dataTest="issue-selection-include-closed"
        />
      </Stack>

      <Stack>
        <ResultsListWrapper data-test="issue-selection-results-list">
          {issues
            ?.filter(
              (issue: IssueSummary) => !excludedIssueUids.includes(issue.uid)
            )
            .map((issue: IssueSummary) => (
              <EmberListChoice
                key={issue.uid}
                option={{
                  id: issue.uid,
                  title: (
                    <Truncate
                      maxWidth={`
                        min(
                          calc(${theme.orbit.widthModalSmall} - 4 * ${theme.orbit.spaceLarge}),
                          calc(100vw - 3 * ${theme.orbit.spaceLarge})
                        )
                      `}
                    >
                      <Text as="span">
                        {issue.title || `Issue ${issue.uid}`}
                        {INACTIVE_ISSUE_STATUSES.includes(issue.status) &&
                          ` (${issueStatusToText(issue.status)})`}
                      </Text>
                    </Truncate>
                  ),
                }}
                dataTest={`issue-selection-item--${issue.uid}`}
                onClick={() => onIssueSelected(issue)}
              />
            ))}
          {!issues?.length && !loading && (
            <Box padding="medium">
              <Text>No matches found</Text>
            </Box>
          )}
        </ResultsListWrapper>
        {total > MAX_ISSUES_IN_MODAL && (
          <Text size="small">
            Showing {MAX_ISSUES_IN_MODAL} of {total} issues. Use the search bar
            to narrow down your search.
          </Text>
        )}
      </Stack>

      {error && (
        <Alert type="critical" dataTest="issue-selection-error">
          <Text>{error.message}</Text>
        </Alert>
      )}
    </Stack>
  )
}

const ResultsListWrapper = styled.div`
  width: ${(props) =>
    `min(
      calc(${props.theme.orbit.widthModalSmall} - 4 * ${props.theme.orbit.spaceMedium}),
      calc(100vw - 4 * ${props.theme.orbit.spaceLarge}),
    )`};
  height: 50vh;
  overflow-y: auto;
  border: solid 1px ${(props) => props.theme.orbit.borderColorCard};
`
