import React from "react"

import { IssueView } from "components/maintenance/issues/details"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = ({ location, params: { issueUid } }) => (
  <MaintenancePage
    issueUid={issueUid}
    title="Issue"
    accessControl={(permissions: MaintenancePermissions) =>
      permissions.mayViewIssueDetails
    }
  >
    <IssueView
      issueUid={issueUid}
      wasJustCreated={!!location?.state?.wasJustCreated}
    />
  </MaintenancePage>
)

export default Page
