// @ts-strict-ignore
import React, { createContext, useContext, useEffect, useState } from "react"

import { parseISO } from "date-fns"
import { Helmet } from "react-helmet"
import styled, { ThemeContext } from "styled-components"
import TimeAgo from "timeago-react"

import {
  Alert,
  Badge,
  Box,
  Grid,
  LinkList,
  Loading,
  Stack,
  Text,
  TextLink,
  Tooltip,
  useMediaQuery,
} from "@kiwicom/orbit-components"
import {
  Alert as AlertIcon,
  Calendar as CalendarIcon,
  ChevronDown,
} from "@kiwicom/orbit-components/icons"

import { EmberApiError } from "api/errors"
import {
  IssuePayload,
  fetchIssueByUid,
  modifyIssue,
  toggleIssueSubscription,
} from "api/issues"
import { deleteUploadedFile } from "api/uploaded-files"

import { ConversationView } from "components/chat"
import { EmberDatePicker } from "components/generic/date-time/ember-date-picker"
import { EditableLabel } from "components/generic/editable-label"
import { EmberInfoBox, InfoRow } from "components/generic/ember-card"
import { EmberSelect, EmberSelectOption } from "components/generic/ember-select"
import { useAttachments } from "components/generic/file-upload"
import {
  DetailsInner,
  DetailsWrapper,
} from "components/generic/multi-column-layout/styled-components"
import { ShadowOnHover } from "components/generic/shadow-on-hover"

import { Activity } from "types/activity"
import {
  Issue,
  IssueRef,
  IssueSeverity,
  IssueStatus,
  MaintenancePermissions,
  issueStatusIsActive,
  issueStatusToText,
} from "types/issue"
import { Unsubscribable } from "types/observable"
import { MinimalProfile, Profile } from "types/person"
import { VehicleSummary } from "types/vehicle"

import {
  formatAsAbbreviatedDate,
  formatAsDateTime,
  getDefaultTimezone,
} from "utils/date-utils"
import { getPersonName } from "utils/name-utils"
import {
  FetchersType,
  useGlobalFetcher,
  useGlobalState,
} from "utils/state-utils"

import {
  IconAndText,
  IssueSeverityBadge,
  IssueStatusBadge,
} from "../components/badges"
import { WithInlineSpinnerAndAlerts } from "../components/inline-spinner-and-alerts"
import {
  ScheduledWorkModal,
  describeScheduledWork,
} from "../components/scheduled-work-modal"
import { VehicleSelector } from "../components/vehicle-selector"
import { MAX_ISSUE_TITLE_LENGTH } from "../shared/constants"
import {
  compileCategoryOptions,
  filterAndSortAssignableUsers,
} from "../shared/loaders"
import { FILE_UPLOAD_CONTEXT, urlForIssue } from "../shared/urls"
import { formatAsRelativeDateTime } from "../shared/utils"
import { IssueSelectionModal } from "./issue-selection-modal"
import { IssueResolutionModal } from "./resolution-modal"

export const IssueView = ({
  issueUid,
  wasJustCreated = false,
}: {
  issueUid: string
  wasJustCreated?: boolean
}) => {
  const [issue, setIssue] = useState<Issue>(null)
  const [error, setError] = useState<EmberApiError>(null)

  useGlobalFetcher("locations")

  useEffect(() => {
    setIssue(null)
    const sub = fetchIssueByUid({
      issueUid,
      onSuccess: setIssue,
      onError: setError,
    })
    return () => sub.unsubscribe()
  }, [issueUid])

  return (
    <>
      <Helmet title={getPageTitle(issue)} />
      {error ? (
        <Alert type="critical" dataTest="issue-loading-error">
          <Text>{error.message}</Text>
        </Alert>
      ) : issue == null ? (
        <Loading type="boxLoader" text="Loading issue..." />
      ) : (
        <>
          {wasJustCreated && (
            <Alert
              type="info"
              title="Thank you for submitting this issue"
              dataTest="thank-you-for-submitting"
            >
              <Text>
                The issue has been saved, details are below. The operations team
                will take it from here.
              </Text>
            </Alert>
          )}
          <IssueDetails issue={issue} setIssue={setIssue} />
        </>
      )}
    </>
  )
}

// This context is just for convenience. It makes the `IssueDetails` component a lot more succinct
// and readable, because it avoids having to pass these params to every child.
export const IssueContext = createContext<{
  issue: Issue
  setIssue: (newIssue: Issue) => void
  readOnlyReason: ReadOnlyReason
  readOnly: boolean // this is for convenience and is true iff `readOnlyReason` is not null
}>(null)

const IssueDetails = ({
  issue,
  setIssue,
}: {
  issue: Issue
  setIssue: (issue: Issue) => void
}) => {
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")
  const { isTablet } = useMediaQuery()
  const readOnlyReason = checkReadOnly(issue, permissions)

  return (
    <DetailsWrapper fullScreen={!isTablet}>
      <DetailsInner fullScreen={!isTablet}>
        <IssueContext.Provider
          value={{
            issue,
            setIssue,
            readOnlyReason,
            readOnly: !!readOnlyReason,
          }}
        >
          <Stack direction="column" spacing="large">
            {readOnlyReason && <ReadOnlyIssueAlert />}

            <Stack direction="column" spacing="none">
              <IssueTextField
                field="title"
                type="heading"
                dataTest="issue-title"
                maxLength={MAX_ISSUE_TITLE_LENGTH}
              />
              <Vehicle />
              <ActivitySelector />
            </Stack>

            <EmberInfoBox sectionTitle="Issue Details" rowGap="16px">
              <CategoryRow />
              <DescriptionRow />
              <IssueSeverityRow />
              <ReportedByRow />
              <DuplicatedByRow />
              <FileAttachmentsRow />
              <ResolutionNoteRow />
            </EmberInfoBox>

            <EmberInfoBox sectionTitle="Status" rowGap="16px">
              <StatusRow />
              <AssigneeRow />
              <SnoozedUntilRow />
              <SubscribersRow />
            </EmberInfoBox>

            {permissions?.mayUseMaintenanceChat && (
              <ConversationView conversation={issue.conversation} />
            )}
          </Stack>
        </IssueContext.Provider>
      </DetailsInner>
    </DetailsWrapper>
  )
}

enum ReadOnlyReason {
  PERMISSION_DENIED = "permission_denied",
  IS_DUPLICATE = "is_duplicate",
  STATUS_IS_CLOSED = "status_is_closed",
}

const checkReadOnly = (
  issue: Issue,
  permissions: MaintenancePermissions
): ReadOnlyReason => {
  // Checks whether the issue should be presented as read-only, giving the `ReadOnlyReason` if any
  if (permissions && !permissions.mayModifyIssues) {
    return ReadOnlyReason.PERMISSION_DENIED
  } else if (issue.duplicateOf) {
    return ReadOnlyReason.IS_DUPLICATE
  } else if (!issueStatusIsActive(issue.status)) {
    return ReadOnlyReason.STATUS_IS_CLOSED
  } else {
    return null
  }
}

const ReadOnlyIssueAlert = () => {
  // This is the alert that appears at the top of the page when either the issue status is closed or
  // marked as a duplicate.
  const { issue, readOnlyReason } = useContext(IssueContext)

  if (readOnlyReason == ReadOnlyReason.PERMISSION_DENIED) {
    // If you don't have permissions to update the issue, we don't explain that or offer actions to
    // change that. The page is read-only and that's that.
    return null
  }

  let reasonText: React.ReactNode = "is read-only"
  let actionLink: React.ReactNode = null
  if (readOnlyReason == ReadOnlyReason.IS_DUPLICATE && issue.duplicateOf) {
    reasonText = (
      <>
        is marked as a duplicate of{" "}
        <TextLink href={urlForIssue(issue.duplicateOf)}>
          {issue.duplicateOf.title}
        </TextLink>
      </>
    )
    actionLink = <MarkAsNoLongerDuplicateButton />
  } else if (readOnlyReason == ReadOnlyReason.STATUS_IS_CLOSED) {
    reasonText = (
      <>
        {issue.updatedAt || issue.updatedBy ? "was" : "has been"}
        {" marked as "}
        <Text as="span" weight="bold">
          {issueStatusToText(issue.status)}
        </Text>
      </>
    )
    actionLink = (
      <StatusEditor
        fixedLabel="change the status"
        showChevron={false}
        dataTest="change-status-of-readonly-issue"
      />
    )
  }

  return (
    <Alert type="info" dataTest="read-only-issue">
      <ReadOnlyIssueAlertLiner>
        <Text>
          This issue {reasonText}
          {issue.updatedAt &&
            " at " + formatAsDateTime(issue.updatedAt, getDefaultTimezone())}
          {issue.updatedBy && " by " + getPersonName(issue.updatedBy)}. It can
          no longer be modified.
        </Text>
        <Text as="div" margin={{ top: "0.5em" }}>
          If you want to make changes you can {actionLink}.
        </Text>
      </ReadOnlyIssueAlertLiner>
    </Alert>
  )
}

const ReadOnlyIssueAlertLiner = styled.div`
  a {
    display: inline; /* prevent weird wrapping on narrow mobile screens */
  }
`

const Vehicle = () => {
  const { issue, saveValue, isSaving, savingError, readOnly } =
    useIssueField("vehicleId")
  return (
    <VehicleSelector
      current={issue.vehicle}
      save={(vehicle: VehicleSummary) => saveValue({ newValue: vehicle.id })}
      isSaving={isSaving}
      savingError={savingError}
      readOnly={readOnly}
    />
  )
}

const ActivitySelector = () => {
  const { issue, setIssue, saveValue, isSaving, savingError, readOnly } =
    useIssueField("activityIds")
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  return (
    <WithInlineSpinnerAndAlerts
      isSaving={isSaving}
      savingError={savingError}
      align="center"
    >
      {!readOnly && (
        <>
          <ShadowOnHover verticalShift="-2px">
            <IconAndText
              icon={<CalendarIcon />}
              text={
                <Stack direction="row">
                  <TextLink
                    type="secondary"
                    noUnderline
                    onClick={() => setModalVisible(true)}
                  >
                    {describeScheduledWork(issue.activities) || "Schedule Work"}
                  </TextLink>
                </Stack>
              }
            />
          </ShadowOnHover>
          {isModalVisible && (
            <ScheduledWorkModal
              vehicle={issue.vehicle}
              existingActivities={issue.activities}
              onActivityIdsChanged={(activityIds: number[]) => {
                setModalVisible(false)
                saveValue({ newValue: activityIds })
              }}
              onActivityObjectsChanged={(activities: Activity[]) => {
                // No need to re-fetch the issue, modify it in place
                setModalVisible(false)
                setIssue({ ...issue, activities })
              }}
              onClose={() => setModalVisible(false)}
            />
          )}
        </>
      )}
    </WithInlineSpinnerAndAlerts>
  )
}

const CategoryRow = () => {
  const { issue } = useContext(IssueContext)
  return (
    <InfoRow label="Category">
      <IssueSelect
        field="categoryUid"
        fetcherId="issueCategories"
        compileOptions={(issueCategories) =>
          compileCategoryOptions({
            issueCategories,
            currentCategory: issue.category,
            includeNotCategorisedOption: true,
          })
        }
        filterable
        currentValue={
          <Text>
            {issue.category ? issue.category.name : "(Not Categorised)"}
          </Text>
        }
        fetcherErrorPrefix="The category cannot be changed because we couldn't load the list of available categories"
        dataTest="category-selector"
      />
    </InfoRow>
  )
}

const DescriptionRow = () => (
  <InfoRow label="Description">
    <IssueTextField field="descriptionMd" type="multiline" />
  </InfoRow>
)

export const IssueSeverityRow = () => {
  const { issue } = useContext(IssueContext)
  const options = Object.values(IssueSeverity).map((severity) => ({
    id: severity ?? "",
    title: <IssueSeverityBadge severity={severity} />,
  }))
  return (
    <InfoRow label="Severity">
      <IssueSelect
        field="severity"
        options={options}
        currentValue={<IssueSeverityBadge severity={issue.severity} />}
        shadowVerticalShift="-2px"
        dataTest="severity"
      />
    </InfoRow>
  )
}

const ReportedByRow = () => {
  const { issue } = useContext(IssueContext)
  const person = getPersonName(issue.createdBy)

  let datetime = formatAsRelativeDateTime(issue.createdAt, getDefaultTimezone())
  if (datetime.startsWith("Today at ")) {
    datetime = datetime.toLowerCase()
  } else {
    datetime = `on ${datetime}`
  }

  return (
    <InfoRow label="Reported By">
      <Text>
        {`${person} ${datetime} (`}
        <TimeAgo datetime={issue.createdAt} />
        {")"}
      </Text>
    </InfoRow>
  )
}

const DuplicatedByRow = () => {
  const { issue } = useContext(IssueContext)
  return (
    issue.duplicatedBy.length > 0 && (
      <InfoRow label="Duplicated By">
        <LinkList>
          {issue.duplicatedBy.map((duplicator: Issue) => (
            <LinkToIssue key={duplicator.uid} issue={duplicator} />
          ))}
        </LinkList>
      </InfoRow>
    )
  )
}

const LinkToIssue = ({ issue }: { issue: Issue | IssueRef }) => (
  <Stack direction="row">
    <TextLink href={urlForIssue(issue)} type="secondary">
      {issue.title}
    </TextLink>
  </Stack>
)

const FileAttachmentsRow = () => {
  const { issue, saveValue, readOnly } = useIssueField("attachments")
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")

  if (!permissions.mayViewAttachments) {
    return null
  }

  const { error, AttachFilesButton, FileGallery } = useAttachments({
    context: FILE_UPLOAD_CONTEXT,
    initialUploadedFiles: issue.attachments,
    editable:
      !readOnly &&
      // NB for now we bundle "may upload" and "may delete" together -- a user needs both in order
      // to do either. If we ever needed to separate them it could be done, it's just not
      // implemented.
      permissions.mayUploadAttachments &&
      permissions.mayDeleteAttachments,
    attachFilesButton: (
      <ShadowOnHover readOnly={readOnly}>
        <TextLink type="secondary">Add</TextLink>
      </ShadowOnHover>
    ),
    autoFetchFileUrls: true,
    confirmBeforeDeleting: true, // since it happens instantly and there is no undo

    // As soon as a file is uploaded, we save the Issue record to add the file to it. This is
    // different from how e.g. chat message attachments work, where the message isn't saved until
    // the "Save" button is pressed.
    postUpload: ({ newFile, onSuccess, onError }) =>
      saveValue({
        newValue: [...issue.attachments, newFile],
        onSuccess,
        onError,
      }),

    // Similarly, we want to detach the file from the issue before we ask the backend to delete
    // the file from the list shown on screen
    preDeletion: ({ file, onSuccess, onError }) =>
      saveValue({
        newValue: issue.attachments.filter((f) => f.uid != file.uid),
        onSuccess: () => {
          deleteUploadedFile({
            context: FILE_UPLOAD_CONTEXT,
            fileUid: file.uid,
            onSuccess,
            onError,
          })
        },
        onError,
      }),
  })

  return (
    <InfoRow label="Attachments">
      <Stack direction="column">
        <FileGallery />
        {!readOnly && <AttachFilesButton />}
        {error && <Alert type="critical">{error.message}</Alert>}
      </Stack>
    </InfoRow>
  )
}

const ResolutionNoteRow = () => (
  <InfoRow label="Resolution Note">
    <IssueTextField field="resolutionNote" type="multiline" />
  </InfoRow>
)

const StatusRow = () => (
  <InfoRow label="Status">
    <StatusEditor dataTest="issue-status" />
  </InfoRow>
)

const StatusEditor = ({
  fixedLabel = null,
  showChevron = true,
  dataTest,
}: {
  fixedLabel?: React.ReactNode
  showChevron?: boolean
  dataTest?: string
}) => {
  const { issue, setIssue } = useContext(IssueContext)
  const statusField = useIssueField("status")
  const duplicateField = useIssueField("duplicateOfUid")
  const [showDuplicateSelector, setShowDuplicateSelector] =
    useState<boolean>(false)
  const [newlySelectedInactiveStatus, setNewlySelectedInactiveStatus] =
    useState<IssueStatus>(null)

  return (
    <>
      <IssueSelect
        field="status"
        options={Object.values(IssueStatus).map((status: IssueStatus) => ({
          id: status ?? "",
          title: <IssueStatusBadge status={status} />,
        }))}
        currentValue={
          fixedLabel ? null : issue.duplicateOf ? (
            <IssueStatusBadge
              status={IssueStatus.DUPLICATE}
              text={
                <Stack
                  direction="column"
                  spacing="XXSmall"
                  desktop={{ direction: "row", spacing: "medium" }}
                >
                  <Text>
                    Duplicate of{" "}
                    <TextLink
                      href={urlForIssue(issue.duplicateOf)}
                      type="secondary"
                    >
                      {issue.duplicateOf.title}
                    </TextLink>
                  </Text>
                  <Text>
                    (
                    <TextLink
                      onClick={() => {
                        duplicateField.saveValue({ newValue: null })
                      }}
                      type="secondary"
                    >
                      Remove
                    </TextLink>
                    )
                  </Text>
                </Stack>
              }
            />
          ) : (
            <IssueStatusBadge status={issue.status} />
          )
        }
        fixedLabel={fixedLabel}
        showChevron={showChevron}
        onChange={(newStatus: IssueStatus) => {
          if (newStatus == IssueStatus.DUPLICATE) {
            setShowDuplicateSelector(true)
          } else if (
            issueStatusIsActive(issue.status) &&
            !issueStatusIsActive(newStatus)
          ) {
            setNewlySelectedInactiveStatus(newStatus)
          } else {
            statusField.saveValue({ newValue: newStatus })
          }
        }}
        shadowVerticalShift="-2px"
        dataTest={dataTest}
      />
      {newlySelectedInactiveStatus && (
        <IssueResolutionModal
          issue={issue}
          setIssue={setIssue}
          pendingChanges={{ status: newlySelectedInactiveStatus }}
          onClose={() => setNewlySelectedInactiveStatus(null)}
        />
      )}
      {showDuplicateSelector && (
        <IssueSelectionModal
          onIssueSelected={(duplicateOf) =>
            duplicateField.saveValue({
              newValue: duplicateOf.uid,
              onSuccess: () => setShowDuplicateSelector(false),
              onError: () => setShowDuplicateSelector(false),
            })
          }
          onClose={() => setShowDuplicateSelector(false)}
          excludedIssueUids={[issue.uid]}
          filterVehicleId={issue?.vehicle?.id}
        />
      )}
    </>
  )
}

export const AssigneeRow = () => {
  const { issue } = useContext(IssueContext)
  const [showAll, setShowAll] = useState<boolean>(false)
  const { loggedInPersonUid } = useGlobalState()

  const compileOptions = (users) => {
    users = filterAndSortAssignableUsers({
      users,
      loggedInPersonUid,
      showAll,
    })
    const options = users.map((user: Profile) => ({
      id: user.uid,
      title: getPersonName(user),
      disabled: user.uid == issue.assignee?.uid,
    }))
    if (options && issue.assignee) {
      options.splice(0, 0, {
        id: "",
        title: "(Unassigned)",
        disabled: false,
      })
    }
    return options
  }

  return (
    <InfoRow label="Assignee">
      <Stack>
        <Stack direction="row" spacing="none" inline shrink>
          <IssueSelect
            field="assigneeUid"
            filterable
            fetcherId="usersAssignableToIssues"
            compileOptions={compileOptions}
            currentValue={
              issue.assignee ? getPersonName(issue.assignee) : "Unassigned"
            }
            additionalFooterActions={
              showAll
                ? null
                : [
                    {
                      label: "Show All",
                      action: () => setShowAll(true),
                    },
                  ]
            }
            dataTest="assignee"
          />
        </Stack>
      </Stack>
    </InfoRow>
  )
}

const SnoozedUntilRow = () => {
  const { issue, saveValue, isSaving, savingError, readOnly } =
    useIssueField("snoozedUntil")

  return (
    !readOnly && (
      <InfoRow label="Next Action Date">
        <WithInlineSpinnerAndAlerts
          isSaving={isSaving}
          savingError={savingError}
          currentValue={
            <Text dataTest="next-action-date">
              {issue.snoozedUntil
                ? formatAsAbbreviatedDate(
                    issue.snoozedUntil,
                    getDefaultTimezone()
                  )
                : "\u2013"}
            </Text>
          }
        >
          <ShadowOnHover>
            <EmberDatePicker
              date={issue.snoozedUntil && parseISO(issue.snoozedUntil)}
              setDate={(newDate: Date) =>
                saveValue({ newValue: newDate.toISOString() })
              }
              iconOnly
              icon={<ChevronDown color="secondary" />}
              timezone={getDefaultTimezone()}
              dataTest="next-action-date-picker"
            />
          </ShadowOnHover>
          <RemoveButton
            field="snoozedUntil"
            currentValue={issue.snoozedUntil}
          />
        </WithInlineSpinnerAndAlerts>
      </InfoRow>
    )
  )
}

const RemoveButton = ({
  field,
  currentValue,
  label = "Remove",
}: {
  field: keyof IssuePayload
  currentValue: unknown
  label?: string
}) => {
  const { saveValue, readOnly } = useIssueField(field)
  return (
    currentValue &&
    !readOnly && (
      <Box padding={{ left: "medium" }}>
        <TextLink
          type="secondary"
          onClick={() => {
            saveValue({ newValue: null })
          }}
        >
          {label}
        </TextLink>
      </Box>
    )
  )
}

const MarkAsNoLongerDuplicateButton = () => {
  const { saveValue, isSaving, savingError } = useIssueField("duplicateOfUid", {
    getChangePayload: (duplicateOf: Issue) => duplicateOf?.uid,
  })

  return (
    <WithInlineSpinnerAndAlerts
      isSaving={isSaving}
      savingError={savingError}
      currentValue={null}
      inline
    >
      <TextLink
        onClick={() => {
          saveValue({ newValue: null })
        }}
        type="secondary"
        dataTest="mark-as-no-longer-a-duplicate"
      >
        mark it as no longer a duplicate
      </TextLink>
    </WithInlineSpinnerAndAlerts>
  )
}

const SubscribersRow = () => {
  const { issue, saveValue, readOnly } = useIssueField("subscribed")
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")
  const { loggedInPersonUid } = useGlobalState()

  const [isSaving, setSaving] = useState<boolean>(false)
  const [error, setError] = useState<EmberApiError>(null)
  const toggle = () => {
    setSaving(true)
    setError(null)
    toggleIssueSubscription({
      issueUid: issue.uid,
      subscribe: !issue.subscribed,
      onSuccess: () => {
        setSaving(false)
        saveValue({ newValue: issue.subscribed })
      },
      onError: (error: EmberApiError) => {
        setSaving(false)
        setError(error)
      },
    })
  }

  return (
    <InfoRow label="Subscribers">
      <Stack direction="column">
        <Stack
          direction="column"
          largeMobile={{ inline: true, direction: "row", wrap: true }}
        >
          {issue.subscribers?.length ? (
            issue.subscribers.map((person: MinimalProfile) => (
              <Badge
                type={person.uid == loggedInPersonUid ? "info" : "infoSubtle"}
                key={person.uid}
              >
                {getPersonName(person)}
              </Badge>
            ))
          ) : (
            <Text>No one</Text>
          )}
          {!readOnly && permissions.maySubscribeToIssues && (
            <WithInlineSpinnerAndAlerts isSaving={isSaving} savingError={error}>
              <ShadowOnHover>
                <TextLink onClick={toggle} type="secondary">
                  {issue.subscribed ? "Unsubscribe" : "Subscribe"}
                </TextLink>
              </ShadowOnHover>
            </WithInlineSpinnerAndAlerts>
          )}
        </Stack>
      </Stack>
    </InfoRow>
  )
}

const IssueTextField = ({
  field,
  type,
  maxLength,
  dataTest,
}: {
  field: "title" | "descriptionMd" | "resolutionNote"
  type: "heading" | "multiline"
  maxLength?: number
  dataTest?: string
}) => {
  const { issue, saveValue, isSaving, savingError, readOnly } = useIssueField(
    field,
    {
      validate: (newValue: string) => {
        if (maxLength && newValue.length > maxLength) {
          throw new Error(`Length cannot exceed ${maxLength}`)
        }
      },
    }
  )
  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const { isDesktop } = useMediaQuery()

  return (
    <Grid columns="1fr 25px" columnGap={theme.orbit.spaceMedium}>
      <EditableLabel
        value={issue[field]}
        setHasPendingChanges={setHasPendingChanges}
        saveValue={saveValue}
        type={type}
        readOnly={readOnly}
        placeholder={`${isDesktop ? "Click" : "Tap"} to add`}
        dataTest={dataTest}
      />
      {isSaving || hasPendingChanges ? (
        <Loading
          type="inlineLoader"
          customSize={parseInt(theme.orbit.spaceMedium)}
        />
      ) : savingError ? (
        <Tooltip
          content={<Text>{`Failed to save: ${savingError.message}`}</Text>}
        >
          <AlertIcon color="critical" />
        </Tooltip>
      ) : null}
    </Grid>
  )
}

/**
 * `IssueSelect` is basically an `EmberSelect`, with the convenience that the parent doesn't need to
 * handle state, onChange events, error displays or anything. The state is read from the current
 * `Issue` object (found in `IssueContext`), and when the selector is changed by the user the issue
 * is saved automatically.
 *
 * In addition this widget takes care of styling to ensure a consistent look and feel across all
 * issue page selectors.
 *
 * If the `options` are known right away (e.g. they're taken from the values of a compiled enum),
 * they should be passed in as the `options` param, otherwise a `fetcherId` and `compileOptions`
 * should be given, and the data will be fetched when the menu is first opened.
 */
const IssueSelect = ({
  field,
  options,
  fetcherId,
  fetcherErrorPrefix,
  compileOptions,
  currentValue,
  fixedLabel = null,
  showChevron = true,
  filterable = false,
  onChange,
  additionalFooterActions,
  shadowVerticalShift,
  dataTest,
}: {
  field: keyof IssuePayload
  options?: EmberSelectOption[]
  fetcherId?: keyof FetchersType
  fetcherErrorPrefix?: string
  compileOptions?: (fetchedData) => EmberSelectOption[]
  currentValue: React.ReactNode
  fixedLabel?: React.ReactNode
  readOnly?: boolean
  showChevron?: boolean
  filterable?: boolean
  onChange?: (newValue) => void
  additionalFooterActions?: { label: string; action: () => void }[]
  shadowVerticalShift?: string
  dataTest?: string
}) => {
  const { saveValue, isSaving, savingError, readOnly } = useIssueField(field)
  const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false)

  // If `fetcherId` is passed in instead of `options`, we fetch the option when the menu is first
  // opened (if they hadn't already been fetched elsewhere)
  let loadingError = null
  let refreshing = false
  if (!options) {
    const fetcher = useGlobalFetcher(fetcherId, { shouldFetch: hasBeenOpened })
    options = fetcher.data ? compileOptions(fetcher.data) : []
    refreshing = fetcher.fetching
    loadingError = fetcher.errorMessage
    if (loadingError && fetcherErrorPrefix) {
      loadingError = `${fetcherErrorPrefix}: ${loadingError}`
    }
  }

  if (readOnly && fixedLabel == null) {
    return <>{currentValue}</>
  }

  return (
    <WithInlineSpinnerAndAlerts
      isReady={!!options || !!fetcherId}
      isSaving={isSaving}
      savingError={savingError}
      loadingError={loadingError}
    >
      <ShadowOnHover readOnly={readOnly} verticalShift={shadowVerticalShift}>
        <EmberSelect
          options={options}
          label={fixedLabel ?? currentValue}
          showChevron={showChevron}
          selectFunction={async (selectedOption: EmberSelectOption) => {
            const newValue = selectedOption.id
            if (onChange) {
              onChange(newValue)
            } else {
              saveValue({ newValue })
            }
          }}
          additionalFooterActions={additionalFooterActions}
          filterable={filterable}
          refreshOnOpen={!!fetcherId}
          refreshFunction={() => setHasBeenOpened(true)}
          refreshing={refreshing}
          dataTest={dataTest}
        />
      </ShadowOnHover>
    </WithInlineSpinnerAndAlerts>
  )
}

/**
 * Utility for automatically saving the issue when changes are made.
 */
const useIssueField = <K extends keyof IssuePayload | "subscribed", V>(
  field: K,
  options: {
    getChangePayload?: (newValue: V) => any
    validate?: (newValue: V) => void
  } = {}
): {
  issue: Issue
  setIssue: (issue: Issue) => void
  saveValue: (params: {
    newValue: V
    onSuccess?: () => void
    onError?: (error: EmberApiError) => void
  }) => Unsubscribable
  isSaving: boolean
  savingError: EmberApiError
  readOnly: boolean
} => {
  const { issue, setIssue, readOnly } = useContext(IssueContext)
  const [isSaving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<EmberApiError>(null)

  const saveValue = ({
    newValue,
    onSuccess,
    onError,
  }: {
    newValue: V
    onSuccess?: () => void
    onError?: (error: EmberApiError) => void
  }): Unsubscribable => {
    if (options.validate) {
      try {
        options.validate(newValue)
      } catch (e) {
        console.log(e)
        setSaving(false)
        setSavingError({ message: `${e}` })
        if (onError) {
          onError({ message: `${e}` })
        }
        return
      }
    }

    setSaving(true)

    const changes = {
      [field]: options.getChangePayload
        ? options.getChangePayload(newValue)
        : newValue,
    }
    if (field == "duplicateOfUid") {
      // If a duplicate is set, we also change the status to IssueStatus.DUPLICATE, and if the
      // duplicate is removed we automatically change the status to IssueStatus.PENDING.
      changes["status"] = changes[field]
        ? IssueStatus.DUPLICATE
        : IssueStatus.PENDING
    }

    return modifyIssue({
      issue,
      changes,
      onSuccess: (newIssue: Issue) => {
        setSaving(false)
        setSavingError(null)
        setIssue(newIssue)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: EmberApiError) => {
        setSaving(false)
        setSavingError(error)
        if (onError) {
          onError(error)
        }
      },
    })
  }

  return {
    issue,
    setIssue,
    saveValue,
    isSaving,
    savingError,
    readOnly,
  }
}

const getPageTitle = (issue: Issue): string => {
  let title = ""
  if (issue) {
    title += issue.title
    if (issue.vehicle?.plateNumber) {
      title += ` - ${issue.vehicle.plateNumber}`
    }
  } else {
    title += "Loading..."
  }
  title += " - Issues"
  return title
}
