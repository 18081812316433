// @ts-strict-ignore
import { EmberSelectOption } from "components/generic/ember-select"

import { IssueCategory } from "types/issue"
import { Profile, RolesNames } from "types/person"

import { getPersonName } from "utils/name-utils"
import { sortBy } from "utils/struct-utils"

/**
 * Returns a list of users (as `Profile` objects) that can be assigned to issues. The list will be
 * sorted such that maintenance folks are put at the top, and then alphabetically. If `showAll` is
 * set to `false`, only maintenance folks will be shown.
 *
 * Note that if we've just loaded the page, and the list of users is still loading, this function
 * will return `null`.
 */
export function filterAndSortAssignableUsers({
  users,
  loggedInPersonUid,
  showAll = true,
}: {
  users: Profile[]
  loggedInPersonUid: string
  showAll?: boolean
}): Profile[] {
  const sortKey = (user: Profile): string => {
    if (user.uid == loggedInPersonUid) {
      return "A"
    } else if (user.roles.includes(RolesNames.MAINTENANCE)) {
      return "B"
    } else {
      return "C"
    }
  }

  return sortBy(
    users.filter((user: Profile) => showAll || sortKey(user) < "C"),
    (user: Profile) => sortKey(user) + getPersonName(user).toLowerCase()
  )
}

/**
 * Takes the data loaded the "issueCategories" global fetcher, and returns a list of
 * `EmberSelectOption` objects, which can then be fed to an EmberSelect to create a drop-down for
 * selecting issue categories.
 */
export const compileCategoryOptions = ({
  issueCategories,
  currentCategory,
  includeNotCategorisedOption = false,
}: {
  issueCategories: IssueCategory[]
  currentCategory: IssueCategory
  includeNotCategorisedOption?: boolean
}): EmberSelectOption[] => {
  const options = issueCategories.map((category: IssueCategory) => ({
    id: category.uid,
    title: category.name,
    group: category.isDefect ? "Defects" : "Other",
  }))
  sortBy(
    // Sort them alphabetically, but put "Other" at the bottom
    options,
    (o) => (o.title.toLowerCase() == "other" ? "zzz" : o.title.toLowerCase())
  )
  if (currentCategory && includeNotCategorisedOption) {
    options.splice(0, 0, {
      id: "",
      title: "(Not Categorised)",
      group: "Other",
    })
  }
  return options
}
